<template>
    <div class="backdrop">
        <div class="modal">
            <div class="modal__close">
            <i @click.self="closeModal" class="fi-rr-cross"></i>
            </div>
            <h4>Zamów usługę już teraz</h4>
            <p>Aby zamówić usługę skontaktuj się z naszym serwisem.</p>
            <div class="modal__contact">
                <div class="modal__contact__type">
                    <i class="fi-rr-smartphone icon"></i>
                    <span>+48 510 188 531</span>
                </div>
                <div class="modal__contact__type">
                    <i class="fi-rr-envelope icon"></i>
                    <span>sklep@kompre.pl</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "ContactServices",
  props: [],
  data() {
    return {
    };
  },
  methods: {
        closeModal() {
            this.$emit('closeContact');
        },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables";
    .icon
    {
        color: $red;
        font-size: 1.25rem;
    }

.modal{
    width: 22.737rem;
    height: auto;
    padding: 1.953rem;
    padding-top: 0.953rem;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4{
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
        font-weight: 500;
        text-align: center;
    }
    p{
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
    &__contact{
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        margin-top: 0.8rem;
        width: 47%;
        &__type{
            display: flex;
            margin-bottom: 0.64rem;
            i{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.25rem;
                cursor: auto;
                margin-right: 0.64rem;
            }
            i::before{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span{
                font-weight: 500;
                font-size: 1rem;
                color: $gray;
            }
        }
        &__type:last-child{
            margin-bottom: 0;
        }
    }
    &__close{   
    i{
        position: absolute;
        right: 1.953rem;
        top: 1.953rem;
        color: #ffffff;
        transition: all 0.2s ease-in-out;
    }
    i::before{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    i:hover{
        color: $red;
        cursor: pointer;
    }
    }
}

.backdrop {
    z-index: 999999;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    .modal{
        width: 100%;
        margin: 1.953rem;
        p{
            text-align: center;
        }
        &__contact{
            width: 100%;
            align-items: center;
        }
    }
}
@media (max-width: 365px) {
    .modal{
        h4{
            font-size: 1rem;
        }
    }
}
</style>
