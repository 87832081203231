<template>
    <div class="product">
        <navbar/>
        <div class="product__content" v-if="!loading" @keyup.37="changeImgWithKeyboard('left')" @keyup.39="changeImgWithKeyboard('right')" tabindex="0">
          <div class="product__content__breadcrumbs">
            <router-link to="/">Główna</router-link>
            <i class="fi-rr-angle-small-right"></i>
            <router-link :to="`/kategorie/${category.link}`">{{category.name}}</router-link>
            <i class="fi-rr-angle-small-right"></i>
            {{product.name}}
          </div>
          <div class="product__content__name">
            <div class="product__content__name__head">
              <h3>{{product.name}}</h3>
            </div>
            <div class="product__content__name__snippet">
              <div v-for="(snippet,snIndex) in product.snippet" :key="snippet"> <span v-if="snIndex > 0" class="snippetConnector">-</span>{{snippet}}</div>
            </div>
          </div>
          <div class="product__content__main">
            <div class="product__content__main__halfLeft">


              <div class="product__content__main__halfLeft__imageContainer" @click="showModal = true">
                  <div class="product__container">
                <img :src="mainImage" class="productImg">
                    <div v-if="product.qualityClass.length > 0 && (product.category === 'Komputery' || product.category === 'Laptopy' || product.category === 'Monitory' || product.category === 'Tablety' || product.category === 'Telefony')" class="product__container__qualityClass">
                        <div :class="{product__container__qualityClass__label: true, labelExhibition: product.qualityClass === 'Powystawowy', labelA: product.qualityClass === 'Nowy' || product.qualityClass === 'A+' || product.qualityClass === 'A' || product.qualityClass === 'A-', labelB: product.qualityClass === 'B+' || product.qualityClass === 'B' || product.qualityClass === 'B-', labelC: product.qualityClass === 'C+' || product.qualityClass === 'C' || product.qualityClass === 'C-', labelFix: (product.promotion && product.isUnique === false) || (product.promotion && product.isUnique) | (product.isUnique === false && product.quantity < 5 && product.promotion === false)}">
                            <span v-if="product.qualityClass === 'A+' || product.qualityClass === 'A' || product.qualityClass === 'A-' || product.qualityClass === 'B+' || product.qualityClass === 'B' || product.qualityClass === 'B-' || product.qualityClass === 'C+' || product.qualityClass === 'C' || product.qualityClass === 'C-'">Klasa {{product.qualityClass}}</span>
                            <span v-if="product.qualityClass === 'Nowy' || product.qualityClass === 'Powystawowy'">{{product.qualityClass}}</span>
                        </div>
                    </div>
                    <div v-if="(product.promotion && product.isUnique === false) || (product.isUnique) | (product.isUnique === false && product.quantity < 5 && product.promotion === false)" class="product__container__special">
                        <span class="redSpan bolded" v-if="product.promotion && product.isUnique === false">Promocja</span>
                        <span class="redSpan" v-if="product.isUnique">Konkretna <span class="graySpan">Sztuka</span></span>
                        <span class="redSpan" v-if="product.isUnique === false && product.quantity < 5 && product.promotion === false">Ostatnie <span class="graySpan">Sztuki</span></span>
                    </div>
                  </div>
              </div>
              
              <div v-if="product.imageGallery.length > 1" class="product__content__main__halfLeft__sliderContainer">
                <div :class="{'product__content__main__halfLeft__moveImages__change': true, 'disabledIcon': sliderData.imageSliderId === 0}" @click="changeImage('previous')"><i class="fi fi-rr-arrow-small-left"></i></div>
                <div :class="{product__content__main__halfLeft__sliderContainer__photoPicker: true, product__content__main__halfLeft__sliderContainer__photoPicker2: product.imageGallery.length === 2}">
                  <div
                  class="product__content__main__halfLeft__sliderContainer__photoPicker__slide"
                  :style="{
                    left: sliderData.computedLeft + 'px'
                  }">
                    <div
                    v-for="(imgData, imgIndx) in product.imageGallery"
                    :key="imgIndx"
                    :class="{product__content__main__halfLeft__sliderContainer__photoPicker__slide__image: true, product__content__main__halfLeft__sliderContainer__photoPicker__slide__picked: (mainImage === product.imageGallery[imgIndx].url)}"
                    :ref="'imageSlider'+imgIndx"
                    @click="updateMainImage(imgData.url,imgIndx)">
                    
                    <img
                    :src="product.imageGallery[imgIndx].url" 
                    />

                    </div>
                  </div>
                </div>
                <div :class="{'product__content__main__halfLeft__moveImages__change': true, 'hidden': true, 'disabledIcon': sliderData.imageSliderId + 3 === product.imageGallery.length}" @click="changeImage('next')"><i class="fi fi-rr-arrow-small-right"></i></div>

                <div :class="{'product__content__main__halfLeft__moveImages__change': true, 'hidden2': true, 'disabledIcon': sliderData.imageSliderId + 2 === product.imageGallery.length}" @click="changeImage('next2')"><i class="fi fi-rr-arrow-small-right"></i></div>
              </div>

            <!-- <div class="product__content__main__halfLeft__moveImages">
                <div :class="{'product__content__main__halfLeft__moveImages__change': true, 'disabledIcon': currentImage.indx === 0}" @click="changeImage('previous')"><i class="fi fi-rr-arrow-small-left"></i></div>
                <div class="product__content__main__halfLeft__moveImages__amountOfImages">Zdjęcie {{currentImage.indx+1}} z {{product.imageGallery.length}}</div>
                <div :class="{'product__content__main__halfLeft__moveImages__change': true, 'disabledIcon': currentImage.indx === product.imageGallery.length-1}" @click="changeImage('next')"><i class="fi fi-rr-arrow-small-right"></i></div>
            </div> -->
            </div>
            <div class="product__content__main__halfRight">
              <div class="product__content__main__halfRight__configurator">
                <div class="product__content__main__halfRight__configurator__head" v-if="product.addons.length>0 && product.active" >
                  Konfigurator
                </div>
                <div class="product__content__main__halfRight__configurator__head" v-if="!product.active" >
                  Produkt niedostępny
                </div>
                <div class="product__content__main__halfRight__configurator__head" v-if="product.addons.length === 0 && product.active && category.name !== 'Usługi'">
                  Kup Teraz!
                </div>
                <div class="product__content__main__halfRight__configurator__head" v-if="product.addons.length === 0 && product.active && category.name === 'Usługi'">
                  Zamów Teraz!
                </div>
                <div class="product__content__main__halfRight__configurator__subtitle" v-if="product.addons.length>0 && product.active">
                  Skonfiguruj swój sprzęt komputerowy.
                </div>
                <div class="product__content__main__halfRight__configurator__subtitle" v-if="!product.active">
                  Przedmiot został wycofany ze sprzedaży.
                </div>
                <div class="product__content__main__halfRight__configurator__subtitle" v-if="product.addons.length===0 && product.active && category.name !== 'Usługi'">
                  Dodaj do koszyka, aby kontynuować.
                </div>
                <div class="product__content__main__halfRight__configurator__subtitle" v-if="product.addons.length===0 && product.active && category.name === 'Usługi'">
                  Aby zamówić usługę, skontaktuj się z nami.
                </div>
                <div class="product__content__main__halfRight__configurator__segment" v-for="addon in product.addons" :key="addon.name" >
                  <div class="product__content__main__halfRight__configurator__segment__name" v-if="product.active">
                    {{addon.name}}
                  </div>
                  <div class="product__content__main__halfRight__configurator__segment__selector" v-if="product.active">
                    <select @change="addonValueChanged(addon,$event)">
                      <option :value="0">Standard</option>
                      <option :value="option.price" v-for="option in addon.options" :key="option.price">{{option.name}} 
                        <span class="smallOption">(+{{option.price.toFixed(2)}} PLN)</span>
                      </option>
                    </select>
                    <i class="fi-rr-angle-down" id="selectorIcon"></i>
                  </div>
                </div>
                <div class="product__content__main__halfRight__configurator__segment" v-if="product.active">
                  <div class="product__content__main__halfRight__configurator__segment__name">
                    CENA BRUTTO
                  </div>
                  <div class="product__content__main__halfRight__configurator__segment__price">
                    <div class="mobileQuantityControl">
                      <div class="product__content__main__halfRight__configurator__segment__price__mobileQuantity" @click="quantityMinusOne()">
                        <div><i class="fi-rr-minus mobileQty"></i></div>
                      </div>
                      <div v-if="category.name !== 'Usługi'" class="product__content__main__halfRight__configurator__segment__price__quantity">
                        <span>Ilość</span>
                        <input @change="fixQuantity()" @keyup="fixQuantity()" type="number" min="1" :max="parseInt(product.quantity)" step="1" v-model="quantity" oninput="this.value = Math.abs(this.value)">
                      </div>
                      <div class="product__content__main__halfRight__configurator__segment__price__mobileQuantity" @click="quantityPlusOne()">
                        <div><i class="fi-rr-plus mobileQty"></i></div>
                      </div>
                    </div>
                    <div :class="{test: true, product__content__main__halfRight__configurator__segment__price__promotion: product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)}">
                      <span class="beforePromotion" v-if="product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)">{{((product.priceBeforePromotion+addonsCosts)*quantity).toFixed(2)}} <span>PLN</span></span>
                      <div>{{((product.basePrice+addonsCosts)*quantity).toFixed(2)}} <span>PLN</span></div>
                    </div>
                    
                  </div>
                  <div v-if="quantityError" class="product__content__main__halfRight__configurator__secondaryInfo"><span>Nie posiadamy większej ilości tego produktu w naszym magazynie.</span></div>
                </div>
                <div class="product__content__main__halfRight__configurator__secondaryInfo" >
                  <span class="netto" v-if="product.active">Netto: {{((product.basePrice+addonsCosts)*quantity/1.23).toFixed(2) + ' PLN'}}</span>
                  <span v-if="product.active && product.category == 'Komputery' || product.category == 'Monitory' ">*Wybór okablowania dostępny podczas finalizacji zakupu.</span>
                  <span v-if="product.active && category.name !== 'Usługi'">*Wysyłka w {{product.deliveryTime}}H.</span>
                  <span class="link" v-if="product.active && category.name !== 'Usługi' && product.inpost.allowed !== true" @click="$router.push('/bezpieczenstwo')">*Darmowa dostawa już przy zakupach od 500 PLN.</span>
                  <span class="link" v-if="product.active && category.name !== 'Usługi' && product.inpost.allowed === true" @click="$router.push('/bezpieczenstwo')">*Darmowa dostawa i zwrot do paczkomatu już przy zakupach od 500 PLN.</span>
                  <span v-if="product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)">*Najniższa cena z 30 dni przed tą promocją: {{((product.priceBeforePromotion+addonsCosts)*quantity).toFixed(2)}} PLN</span>
                  <span v-if="!product.active">*Skontaktuj się ze sprzedawcą w celu sprawdzenia dostępności.</span>
                  <span v-if="category.name === 'Usługi'">*Oferta ma charakter informacyjny, skontaktuj się z nami w celu zamówienia usługi.</span>
                </div>
                <div v-if="category.name !== 'Usługi'" class="product__content__main__halfRight__configurator__confirm">
                  <mainButton :class="{quantityZero: quantity < 1}" v-if="product.active" :cta="buttonName" @clicked="addToCart()"></mainButton>
                  <mainButton :class="{quantityZero: true}" v-if="!product.active" :cta="buttonName"></mainButton>
                </div>
                <div class="product__content__main__halfRight__configurator__confirm">
                  <mainButton v-if="product.active && category.name === 'Usługi'" cta="Zamów usługę" @clicked="toggleContact()"></mainButton>
                </div>
              </div>
            </div>
          </div>

            <div class="product__content__sectionTitle" v-if="product.descSections.length>0 && category.name !== 'Usługi'" data-aos="fade-up">
            <div class="product__content__sectionTitle__title">
              Więcej o produkcie
            </div>
            <div class="product__content__sectionTitle__subtitle">
              Dowiedz się więcej przed zakupem.
            </div>
          </div>
          <div class="product__content__sectionTitle" v-if="product.descSections.length>0 && category.name === 'Usługi'" data-aos="fade-up">
            <div class="product__content__sectionTitle__title">
              Więcej o usłudze
            </div>
            <div class="product__content__sectionTitle__subtitle">
              Dowiedz się więcej przed zamówieniem.
            </div>
          </div>
          <div class="product__content__description" v-if="product.descSections.length>0">
            <article :class="{'reversed': secIndx%2===0}" v-for="(section,secIndx) in product.descSections" :key="secIndx" data-aos="fade-up" data-aos-offset="20">
              <div class="descriptionImage"><img :src="section.url" :alt="section.keyword"></div>
              <div class="descriptionText">
                <h1 v-if="secIndx === 0">{{section.header}}</h1>
                <h3 v-if="secIndx !== 0">{{section.header}}</h3>
                <p v-html="section.text"></p>
              </div>
            </article>
            
          </div>
          <div class="product__content__sectionTitle" v-if="activeSpecs.length>0 && category.name !== 'Usługi'" data-aos="fade-up">
            <div class="product__content__sectionTitle__title">
              Specyfikacja
            </div>
            <div class="product__content__sectionTitle__subtitle">
              Prosta tabela, wiele odpowiedzi.
            </div>
          </div>
          <div class="product__content__specs" v-if="activeSpecs.length>0 && basicSpecs.length>0">
            <div :class="{'product__content__specs__tile': true, divideTwo: index%2===0}" v-for="(spec,index) in basicSpecs" :key="spec.name" data-aos="fade-up" data-aos-offset="20">
              <div class="product__content__specs__tile__name">
                {{spec.name}}:
              </div>
              <div class="product__content__specs__tile__value">
                {{spec.value}}
              </div>
            </div>
          </div>
          <div class="product__content__sectionTitle" v-if="activeSpecs.length> 0 && specsSpecs.length>0" data-aos="fade-up">
            <div class="product__content__sectionTitle__title">
              Parametry podstawowe
            </div>
          </div>
          <div class="product__content__specs" v-if="activeSpecs.length>0 && specsSpecs.length>0">
            <div :class="{'product__content__specs__tile': true, divideTwo: index%2===0}" v-for="(spec,index) in specsSpecs" :key="spec.name" data-aos="fade-up" data-aos-offset="20">
              <div class="product__content__specs__tile__name">
                {{spec.name}}:
              </div>
              <div class="product__content__specs__tile__value">
                {{spec.value}}
              </div>
            </div>
          </div>
          <div class="product__content__sectionTitle" v-if="activeSpecs.length>0 && moreSpecs.length>0" data-aos="fade-up">
            <div class="product__content__sectionTitle__title">
              Więcej informacji
            </div>
          </div>
          <div class="product__content__specs" v-if="activeSpecs.length>0 && moreSpecs.length>0">
            <div :class="{'product__content__specs__tile': true, divideTwo: index%2===0}" v-for="(spec,index) in moreSpecs" :key="spec.name" data-aos="fade-up" data-aos-offset="20">
              <div class="product__content__specs__tile__name">
                {{spec.name}}:
              </div>
              <div class="product__content__specs__tile__value">
                {{spec.value}}
              </div>
            </div>
          </div>
          <div class="product__additional" data-aos="fade-up" data-aos-offset="20">
            <div class="product__additional__label">
              <span v-if="this.product.baselinkerProductID">ID: {{ this.product.baselinkerProductID }}</span>
            </div>
            <div class="product__additional__label">
              <span v-if="this.product.sku">/ SKU: {{ this.product.sku }}</span>
            </div>
          </div>
        </div>
        <transition name="fade">
          <modal v-if="showModal" @updateUrl="updateUrl" @updateImgIndx="updateIndx" @close="closeModal()" :currentImage="currentImage" :product="product" :mainImage="mainImage" :sliderData="sliderData" @photoChange="photoChange"></modal>
        </transition>
        <transition name="fade">
          <contactServices v-if="showContactModal" @closeContact="closeContactModal()"></contactServices>
        </transition>
        <loader v-if="loading"></loader>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
// Imports
    import {
    db
    } from '../configs/firebase';
    import loader from "../components/loader.vue";
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import mainButton from "../components/mainButton.vue";
    import suggestedProducts from "../components/suggestedProducts.vue";
    import modal from "../components/ModalImage.vue";
    import contactServices  from '@/components/contactServicesModal.vue';
    import moment from 'moment';
export default {
    emits: ["toggleScroll"],
    data() {
      return {
        hideImageSelector: true,
        buttonName: 'Dodaj do koszyka',
        loading: true,
        product: {
        },
        category: {},
        mainImageIndex: 0, // Left and right arrows
        mainImage: "",
        currentImage: {
          url: "",
          indx: 0,
          keyword: ""
        },
        activeSpecs: [],
        basicSpecs: [],
        specsSpecs: [],
        moreSpecs: [],
        selectedAddons: [],
        addonsCosts: 0,
        quantity: 1,
        quantityError: false,
        showModal: false,
        showContactModal: false,
        sliderData:{
          computedLeft: 0,
          imageSliderId: 0,
        },
      }
    },
    methods: {
      fixQuantity(){
        let productQuantity = parseInt(this.product.quantity)
        if(this.quantity >= productQuantity){
          this.quantity = productQuantity
          this.quantityError = true
        }else{
          this.quantityError = false
        }
        
      },
      toggleContact(){
        this.showContactModal = true;
        this.$emit("toggleScroll");
      },
      closeContactModal(){
        this.showContactModal = false;
        this.$emit("toggleScroll");
      },
      changeImgWithKeyboard(key)
      {
        if(key==="left")
        {
          this.changeImage('previous');
          if(this.mainImageIndex-1 >= 0)
          {
            this.updateMainImage(this.product.imageGallery[this.mainImageIndex-1].url,this.mainImageIndex-1)
          }
          
        }
        if(key==="right")
        {
          this.changeImage('next');
          if(this.mainImageIndex+1 < this.product.imageGallery.length)
          {
            this.updateMainImage(this.product.imageGallery[this.mainImageIndex+1].url,this.mainImageIndex+1)
          } 
        }
      },
      updateUrl(url)
      {
        this.mainImage = url;
      },
      updateIndx(indx)
      {
        this.mainImageIndex = indx;
      },
      updateMainImage(url,index){
        this.mainImage = url;
        this.mainImageIndex = index;
      },
      // Seo
      setSeo()
      {
        document.title = `${this.product.name} | Outlet Poleasingowy Kompre`;
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", `${this.product.name},komputery poleasingowe,laptop poleasingowy,laptop uzywany,najtansze monitory,tanie monitory,tani komputer,tanie laptopy,tanie monitory`);
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", `Zobacz ${this.product.name}.Najtańsze laptopy poleasingowe, tanie komputery oraz monitory używane znanych producentów. Sprawdź sklep komputerowy Kompre.`);
      },
      // Courier
      getCourierWeight(categoryName)
      {
        let weight = 1;
        if(categoryName === "Komputery")
        {
          if(this.product.specs.casing.value.length>0)
          {
            for(let i=0;i<this.category.courierWeights.length;i++)
            {
              let currentWeight = this.category.courierWeights[i];
              if(currentWeight.name === this.product.specs.casing.value)
              {
                weight = currentWeight.cap;
              }
            }
          }
        }
        if(categoryName === "Akcesoria")
        {
          const subcat = this.product.subcategory;
          for(let i=0; i<this.category.courierWeights.length;i++)
          {
            let currentCap = this.category.courierWeights[i];
            if(currentCap.name === subcat)
            {
              weight = currentCap.cap;
            }
          }
        }
        if(categoryName === "Części komputerowe" || categoryName === "Części laptopowe" || categoryName === "Części do komputera" || categoryName === "Części do laptopów" || categoryName === "Laptopy" || categoryName === "Tablety" || categoryName === "Telefony" || categoryName === "Monitory")
        {
          weight = this.category.courierWeight;
        }
        return parseFloat(weight);
      },
      // Inpost
      getInpostCap(categoryName)
      {
        let cap = null;
        if(categoryName === "Komputery")
        {
          if(this.product.specs.casing.value.length>0)
          {
            for(let i=0;i<this.category.inPostCapacityList.length;i++)
            {
              let currentCap = this.category.inPostCapacityList[i];
              if(currentCap.name === this.product.specs.casing.value)
              {
                cap = currentCap.cap;
              }
            }
          }
        }
        if(categoryName === "Akcesoria")
        {
          const subcat = this.product.subcategory;
          for(let i=0; i<this.category.inPostCapacityList.length;i++)
          {
            let currentCap = this.category.inPostCapacityList[i];
            if(currentCap.name === subcat)
            {
              
              cap = currentCap.cap;
            }
          }
        }
        if(categoryName === "Części komputerowe" || categoryName === "Części laptopowe" || categoryName === "Części do komputera" || categoryName === "Części do laptopów" || categoryName === "Laptopy" || categoryName === "Tablety" || categoryName === "Telefony")
        {
          cap = this.category.inpostCap;
        }
        return cap;
      },
      changeImage(move)
      {
            if(move === "next")
            {
              if(this.sliderData.imageSliderId + 3 < this.product.imageGallery.length){
                const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId+1)];
                const firstImageBounding = firstImage[0].getBoundingClientRect();
                const secondImageBounding = secondImage[0].getBoundingClientRect();
                this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                this.sliderData.imageSliderId = this.sliderData.imageSliderId + 1
              }
            }
            if(move === "next2")
            {
              if(this.sliderData.imageSliderId + 2 < this.product.imageGallery.length){
                const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId+1)];
                const firstImageBounding = firstImage[0].getBoundingClientRect();
                const secondImageBounding = secondImage[0].getBoundingClientRect();
                this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                this.sliderData.imageSliderId = this.sliderData.imageSliderId + 1
              }
            }
            if(move === 'previous')
            {
              if(this.sliderData.imageSliderId > 0){
                const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId-1)];
                const firstImageBounding = firstImage[0].getBoundingClientRect();
                const secondImageBounding = secondImage[0].getBoundingClientRect();
                this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                this.sliderData.imageSliderId = this.sliderData.imageSliderId - 1
              }
            }
      },
      photoChange(currentImage)
      {
        this.currentImage = currentImage;
      },
      closeModal()
      {
        this.showModal = false;
      },
      quantityPlusOne()
      {
        if(this.quantity<100)
        {
           this.quantity++;
           this.fixQuantity();
        }
      },
      quantityMinusOne()
      {
        if(this.quantity>1)
        {
           this.quantity--;
           this.fixQuantity();
        }
      },
      addToCart()
      {
        if(this.product.inpost === undefined)
        {
          this.product.inpost = {
              allowed: false,
          }
        }
        let item = {
          inpostCap: this.getInpostCap(this.category.name),
          courierWeight: this.getCourierWeight(this.category.name),
          product: this.product,
          quantity: parseInt(this.quantity),
          addons: this.selectedAddons,
          positionPrice: parseFloat(this.product.basePrice)+parseFloat(this.addonsCosts),
          addTime: moment().unix()
        }
        if(this.quantity>0)
        {
          this.$store.commit('add', item);
          this.$router.push('/koszyk');
        }
      },
      calculateAddonPrices()
      {
        this.addonsCosts = 0;
        if(this.selectedAddons.length === 0)
        {
          this.addonsCosts = 0;
        }
        else
        {
          this.selectedAddons.forEach((item)=>
          {
            this.addonsCosts = this.addonsCosts + item.addonPrice;
            
          })
        }
      },
      addonValueChanged(addon,event)
      {
        let optionName = event.target.options[event.target.selectedIndex].text;
        let toDelete = optionName.substring(optionName.indexOf("(")-1,optionName.indexOf(")")+1);
        optionName = optionName.replace(toDelete,"")
        let selectedAddon = {
          addonName: addon.name,
          optionName: optionName,
          addonPrice: parseFloat(event.target.value)
        };

        this.selectedAddons.forEach(function(item,index,array)
        {
          if(item.addonName === selectedAddon.addonName)
          {
            array.splice(index,1);
          }
          
        })

        if(selectedAddon.optionName !== "Standard")
        {
          
          this.selectedAddons.push(selectedAddon);
        }
        else
        {
          
        }
        this.calculateAddonPrices();
      },
      setCurrentImage(image)
      {
        window.scrollTo(0,50);
        this.currentImage = image;
      },
      setSpecs(specs)
      {
        for(let i=0;i<specs.length; i++)
        {
          let currentItem = specs[i];
          if(currentItem.value.length>0)
          {
            this.activeSpecs.push(currentItem);
          }
        }
        for(let i=0; i<this.activeSpecs.length; i++)
        {
          let currentItem = this.activeSpecs[i];
          if(currentItem.type === "Basic")
          {
            this.basicSpecs.push(currentItem);
          }
          if(currentItem.type === "More")
          {
            this.moreSpecs.push(currentItem);
          }
          if(currentItem.type === "Specs")
          {
            this.specsSpecs.push(currentItem);
          }
        }
        this.basicSpecs.sort((a, b) => (a.place > b.place) ? 1 : -1)
        this.moreSpecs.sort((a, b) => (a.place > b.place) ? 1 : -1)
        this.specsSpecs.sort((a, b) => (a.place > b.place) ? 1 : -1)
        //this.activeSpecs.sort((a, b) => (a.place > b.place) ? 1 : -1)
      },
      getProductData(id)
      {
        db.collection("Products").doc(id).get().then((snapshot)=>
        {
          if(snapshot.exists)
          {
            this.product = snapshot.data();
            this.setSeo();
            if(this.product.manufacturer.length>0)
            {
              this.product.specs.manufacturer = {
                      name: 'Producent',
                      value: this.product.manufacturer,
                      place: 2,
                      type: 'Basic'
              };
            }
            if(this.product.model.length>0)
            {
                this.product.specs.model = {
                    name: 'Model',
                    value: this.product.model,
                    place: 3,
                    type: 'Basic'
            };
            }
            this.product.imageGallery.unshift(this.product.mainImage);
            this.setSpecs(Object.values(this.product.specs))
            this.currentImage = this.product.mainImage;
            this.mainImage = this.product.mainImage.url;
            if(!this.product.active)
            {
              this.buttonName = "Niedostępny"
            }
            db.collection("Categories").doc(this.product.category).get().then((cathInfo)=>
            {
              this.category = cathInfo.data();
              if(this.product.addonsIds.length>0)
              {
                  for(let i=0; i<this.product.addonsIds.length; i++)
                  {
                    this.loading = true;
                    db.collection("SavedProductAddons").doc(this.product.addonsIds[i].id).get().then((snapshot)=>
                    {
                      if(snapshot.exists)
                      {
                        let data = snapshot.data();
                        let unfiltredOptions = [];

                        unfiltredOptions = unfiltredOptions.concat(data.options);
                        data.options = [];
                        for(let unfiltredIndx = 0; unfiltredIndx<unfiltredOptions.length;unfiltredIndx++)
                        {
                          if(unfiltredOptions[unfiltredIndx].availableIn.includes(this.product.uniqueID) === true)
                          {
                            data.options.push(unfiltredOptions[unfiltredIndx]);
                          }
                        }
                        for(let z=0; z<data.options.length;z++)
                        {
                          if(data.percantagePrice)
                          {
                            data.options[z].price = parseFloat(this.product.basePrice*(data.options[z].percantage/100).toFixed(2));
                          }       
                        }
                        if(data.options.length>0)
                        {
                          this.product.addons.push(data);
                        }
                      }
                      this.loading = false;
                    }).catch((error)=>
                    {
                      console.log(error);
                    })
                  }      
              }
              else 
              {
                this.loading = false;
              }

            }).catch((error)=>
            {
              console.log(error);
              
            })
          }
          else
          {
            this.$router.push('/');
          }
        }).catch((error)=>
        {
          console.log(error);
          this.$router.push('/');
          
        })
      }
    },
    created()
    {
      window.scrollTo(0,0);
      this.loading = true;
      this.getProductData(this.$route.params.id);
    },
    watch:
    {
        $route(from,to)
        {
            window.scrollTo(0,0);
            this.loading = true;
            this.getProductData(to.params.id);
        }
    },
    props: ['id'],
    components: {navbar,trust,ftr,loader,mainButton,suggestedProducts,modal, contactServices, contactServices},
}
</script>
<style lang="scss" scoped>
    @import '../styles/variables';
.link{
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.link:hover{
  color: rgb(200, 0, 0);
}

.beforePromotion{
    color: $red !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: line-through;
    line-height: 0.8rem !important;
        span{
          color: $red !important;
          line-height: 0.8rem !important;
        }
    }
    .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .test{
      margin-top: auto;
      // margin-bottom: 0.134rem;
    }
    #selectorIcon
    {
      display: block;
      position: relative;
      right: 1rem;
      background-color: white;
      z-index: 1;
    }
    .hidden2{
      display: none !important;
    }

    .snippetConnector
    {
        color: $connectorColor;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .product
    {
      margin-top: 10.527em;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: auto;

      &__additional
      {
        display: flex;
        justify-content: flex-end;
        gap: 4px;

        &__label
        {
          color: rgb(145, 145, 145);
          font-size: 12px;
          user-select: text;
        }
        
      }

       &__container
        {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            padding: 5%;
            width: 90%;
            height: 90%;
            transition: box-shadow 0.4s ease-in-out;

            &__special
            {
                height: 7%;
                z-index: 300;
            }

            &__qualityClass
            {
                font-size: 0.8rem;
                position: relative;
                height: 0;
                width: 100%;
                // background: $black;  
                &__label{
                    z-index: 10;
                    position: absolute;
                    right: -5.5%;
                    top: -40px;
                    height: 1.563rem;
                    width: 4rem;
                    background: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.16);
                    clip-path: polygon(100% 0, 100% 85%, 100% 100%, 0% 100%, 10% 50%, 0 0);
                    span{
                        color: #ffffff;
                        padding-left: 10%;
                    }
                }
            }

            &__content
            {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 93%;
                
                &__name
                {
                    margin-top: 0.64rem;
                    text-align: center;
                }

                &__image
                {
                    margin-top: 0.3125em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    height: 60%;
                    background: #ffffff !important;
                    border: none !important;
                    
                    
                    img
                    {
                        transition: all 0.7s ease-in-out;
                    }
                    
                    &__img
                    {
                        object-fit: fill;
                        max-width: 60%;
                        max-height: 100%;
                        background: #ffffff !important;
                        border: none !important;
                    }
                }

                &__snippet
                {
                    width: 100%;
                    font-size: 0.8rem;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__price
                {
                    width: 100%;
                    text-align: center;
                    // margin-top: 0.262rem;
                    display: flex;
                    justify-content: center;
                    font-size: 1.25rem;
                    font-weight: 500;
                }

                &__callToAction
                {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    // margin-top: auto;
                    font-size: 1rem;
                    color: $red;
                    transition: all 0.4s ease-in-out;
                    text-align: center;
                    &__icon{
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

        }

        &__container:hover
        {
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }

      &__content
      {
        padding-left: 5vw;
        padding-right: 5vw;
        width: 90%;
        display: flex;
        flex-direction: column;

        &__specs
        {
          user-select: text;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1.563rem;
          font-size: 0.889rem;

          &__tile
          {
            display: flex;
            align-items: center;
            background-color: #F0F0F0;
            width: 100%;
            padding-top: 0.41rem;
            padding-bottom: 0.41rem;
            margin-bottom: 0.41rem;
            border-radius: 3px;

            &__name
            {
              padding-left: 1rem;
              font-weight: 500;
              min-width: 14%;
              border-right: 1px solid rgb(211, 211, 211);
            }

            &__value
            {
              margin-left: 0.702rem;
            }
          }
        }

        &__sectionTitle
        {
          user-select: none;
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
          padding-bottom: 1.25rem;
          border-bottom: 1px solid $border;

          &__title
          {
            font-weight: 500;
            font-size: 1.25rem;
          }

          &__subtitle
          {
            font-weight: 400;
            font-size: 1rem;
          }
        }

        &__description
        {
          user-select: none;
          display: flex;
          width: 95%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          

          article
          {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.953rem;
            margin-bottom: 3.052rem;

            &__image
            {
              width: 70%;
            }
          }
        }

        &__imageSelector
        {
            margin-bottom: 1.563rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            &__image
            {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 4.768rem;
              width: 4.768rem;
              overflow: hidden;
              border: 2px solid $border;
              margin-right: 0.8rem;
              margin-top: 0.8rem;
              transition: all 0.4s ease-in-out;
              border-radius: 2px;

              img
              {
                
                object-fit: cover;
                height: 100%;
                width: 100%;
              }
            }

            &__image:hover
            {
              cursor: pointer;
              opacity: 0.5;
            }


        }
        
        &__breadcrumbs
        {
          font-size: 0.8rem;
          color: $gray;
          display: flex;
          align-items: center;
          user-select: none;

          i
          {
            display: flex;
            justify-content: center;
            align-content: center;
            padding-left: 0.125rem;
            padding-right: 0.125rem;
          }

          a:hover
          {
            color: $red;
          }
        }

        &__name
        {
          display: flex;
          align-items: center;

          &__head
          {
            h3
            {
              padding: 0;
              margin: 0;
              font-weight: 500;
              font-size: 1.953rem;
            }
          }

          &__snippet
          {
            display: flex;
            margin-left: 1rem;
            font-size: 1rem;
            font-weight: 500;
            color: $gray;
            user-select: none;
          }
        }

        &__main
        {
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
          display: flex;
          width: 100%;
          height: auto;

          &__halfLeft
          {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 45%;

            &__sliderContainer{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 4.768rem;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              margin-top: 1rem;
              user-select: none;
              -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
              -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
              &__photoPicker{
                  width: 23.953rem;
                  height: 4.768rem;
                  position: relative;
                  overflow-x: hidden;
                  overflow-y: hidden;
                  margin-top: 0.21rem;
                &__slide{
                  display: flex;
                  position: absolute;
                  left: 0;
                  transition: 0.3s left ease-in-out;
                  &__image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #d8d8d8;
                    width: 7.451rem;
                    height: 4.768rem;
                    background: white;
                    margin-right: 0.8rem;
                    transition: border 0.2s ease-in;
                    padding: 0.41rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    img{
                      max-height: 100%;
                      max-width: 100%;
                    }
                  }
                  &__image:last-child{
                    margin-right: 0;
                  }
                  &__image:hover{
                    cursor: pointer;
                    border: 1px solid rgba(191, 80, 87, 0.8)
                  }
                  &__picked{
                    border: 1px solid $red;
                  }
                }
              }
              &__photoPicker2{
                width: 15.702rem !important;
              }
            }


            &__moveImages
            {
                display: flex;
                padding-right: 5%;
                padding-left: 5%;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-top: 1rem;
                color: $gray;

                &__amountOfImages
                {
                  display: flex;
                  user-select: none;
                  justify-content: center;
                }

                &__change
                {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 100%;
                  margin-right: 1rem;
                  margin-left: 1rem;
                  cursor: pointer;
                  font-size: 1.953rem;
                  i::before{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }

                &__change:hover
                {
                  color: $red;
                }
            }

            &__imageContainer
            {
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 65vh;
              overflow: hidden;
              border: 1px solid $border;
              transition: transform 0.5s ease-in;
              
              img
              {
                object-fit: contain;
                width: 100%;
                height: 80%;
              }
            }

            &__imageContainer:hover
            {
              cursor: pointer;
              transform: scale(1.03);
            }
            
          }

          &__halfRight
          {
            width: 55%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__configurator
            {
              width: 50%;
              user-select: none;

              &__head
              {
                font-size: 1rem;
                font-weight: 500;
              }

              &__subtitle
              {
                font-size: 1rem;
                font-weight: 400;
              }

              &__secondaryInfo
              {
                color: $red;
                display: flex;
                flex-direction: column;
                font-size: 0.8rem;

                span
                {
                  margin-top: 0.41rem;
                  display: block; 
                }
              }

              &__segment
              {
                color: $black;
                margin-top: 1rem;
                width: 100%;

                &__selector
                {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  transition: all 0.4s ease-in;

                  select::-ms-expand {
                    display: none;
                  }

                  select
                  {
                    z-index: 2;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    width: 100%;
                    font-size: 1rem;
                    border: none;
                    outline: none;
                    padding-top: 0.8rem;
                    padding-bottom: 0.8rem;
                    border-bottom: 2px solid $gray;
                    transition: all 0.4s ease-in;
                    background-color: rgba(255, 255, 255, 0);
                    // color: #000000;
                  }

                  select:hover
                  {
                    cursor: pointer;
                    border-bottom: 2px solid $redHover;
                    color: $redHover;
                  }
                }

                &__selector:hover
                {
                  color: $redHover;
                }


                &__name
                {
                  color: $gray;
                }

                &__price
                {
                  margin-top: 0.512rem;
                  display: flex;
                  align-items: center;
                  font-weight: 400;
                  font-size: 1.953rem;
                  line-height: 1.953rem;
                  &__promotion{
                    display: flex;
                    flex-direction: column;
                  }

                  &__mobileQuantity
                  {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    color: $red;
                    
                    div
                    {       
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 1em;
                      i{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }
                  
                  &__quantity
                  {
                    display: flex;
                    align-items: center;
                    margin-right: 1.25rem;
                    
                    
                    input
                    {
                      text-align: center;
                      height: 1.953rem;
                      border: 1px solid $gray;
                      border-radius: 5px;
                      user-select: none;
                      outline: none;
                      transition: all 0.2s ease-in;
                    }

                    input:focus
                    {
                      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                      border: 1px solid $red;
                    }

                    input:hover
                    {
                      border: 1px solid black;
                      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                    }

                    input[type=number]::-webkit-inner-spin-button, 
                    input[type=number]::-webkit-outer-spin-button {  

                      opacity: 1;

                    }

                    span
                    {
                      font-size: 0.64rem;
                    }
                  }
                  // div{
                  //   margin-top: auto;
                  //   margin-bottom: auto;
                  // }

                  span
                  {
                    margin-right: 0.8rem;
                    color: $gray;
                  }

                }


              }


              
              
            }
          }
        }
      }
    }

    .descriptionImage
    {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      overflow: hidden;

      img
      {
        width: 65%;
        height: 65%;
        object-fit: contain;
      }
    }

    .descriptionText
    {
      display: flex;
      flex-direction: column;
      width: 50%;

      h1,h3
      {
        margin: 0;
        padding: 0;
        font-size: 1.563rem;
        font-weight: 400;
        margin-bottom: 0.8rem;
      }

      p
      {
        text-align: justify;
        text-justify: inter-word;
        margin: 0;
        padding: 0;
        font-size: 1rem;
      }
    }
        
    .chosen
    {
      border: 2px solid $redHover;
    }

    .divideTwo
    {
      background-color: #F7F7F7;
    }

    @media (max-width: 1600px)
    {
      .product__content__main__halfRight__configurator
      {
        width: 80%;
      }
    }


    @media (max-width: 1024px)
    {
      .beforePromotion{
        display: flex;
        justify-content: center;
        // margin-left: auto;
        // margin-right: auto;
        margin-top: 0.41rem;
        span{
          margin-right: 0;
        }
      }
      .product__content__main__halfRight__configurator__segment__price__mobileQuantity
      {
        
        display: flex;
        align-items: center;
      }

      .product__content__main__halfRight__configurator__segment__price
      {
        flex-direction: column;
      }

      .product__content
      {
        &__specs
        {
          
          align-items: flex-start;
          width: 100%;
          
          &__tile
          {
            user-select: none;
            flex-direction: column;
            align-items: center;
            padding: 0;

            &__name
            {
              
              padding: 0;
              padding-left: 1.25rem;
              padding-top: 0.8rem;
              width: 100%;
            }

            &__value
            {
              margin-left: 0;
              padding-left: 1.25rem;
              padding-bottom: 0.8rem;
              width: 100%;
            }
          }
        }
      }



      .product__content
      {
        &__name
        {
          flex-direction: column;
          width: 100%;
          align-items: flex-start;

          &__snippet
          {
            margin-left: 0;
          }
        }

        &__main
        {
          flex-direction: column;

          &__halfLeft
          {
            width: 100%;
          }

          &__halfRight
          {
            width: 100%;

            &__configurator
            {
              margin-top: 1.25rem;
              width: 100%;

            }
          }
        }

        &__description
        {
          user-select: none;
          article
          {
            margin-bottom: 1.953rem;
            flex-direction: column;
            width: 100%;
            margin-top: 1.953rem;
          }
        }
      }


      .descriptionImage
      {
        width: 100%;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;

        img
        {
          width: 90%;
        }
      }

      .descriptionText
      {
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
      }

      .reversed
      {
        flex-direction: row;
      }
    }

    .quantityZero
    {
      opacity: 0.2;
    }

  .mobileQty
  {
    display: block;
    padding: 0.512rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.14), 0 1px 2px rgba(0,0,0,0.28);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.4s ease-in;
  }

  .mobileQty:hover
  {
    color: $redHover;
    box-shadow: 0 2px 14px rgba(0,0,0,0.14), 0 10px 10px rgba(0,0,0,0.12);
  }

  .mobileQuantityControl
  {
    display: flex;
    // margin-top: 1rem;
    align-items: center;
    justify-content: center;
  }

  .reversed
  {
    flex-direction: row-reverse;
  }

  @media (max-width: 1366px)
  {
    .product__content__main__halfRight__configurator__segment__price
    {
      font-size: 1.563rem; 
    }

    .product__content__name__head
    {
      h3
      {
        font-size: 1.563rem; 
      }
    }

  }
  @media (max-width: 769px) {
    .product{
        margin-top: 4.768em;
    }
  }
    @media (max-width: 500px) {
    .hidden{
      display: none !important;
    }
    .hidden2{
      display: block !important;
    }
  }
  

.disabledIcon
{
    color: $border;
}

.disabledIcon:hover
{
    color: $border;
}

.netto
{
  padding-top: 0.41rem;
  padding-bottom: 0.41rem;
  color: $gray;
}


.productImg
{
  transition: transform 0.4s ease-in;
}

.productImg:hover
{
  transform: scale(1.10);
}

    .snippetConnector
    {
        color: $connectorColor;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .smallIcon
    {
        font-size: 1rem;
    }

    h3{
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
    }
    
.labelA{
        background: #00a606;
    }
    .labelB{
        background: #c2cc00;
    }
    .labelC{
        background: #e77f00;
    }
    .labelExhibition{
        background: #00a606;
        width: 5.96rem;
        font-size: 0.64rem;
    }
    .redSpan
    {
        z-index: 500;
        color: $red;
        font-size: 1.115rem !important;
    }
    .bolded{
        font-weight: 500;
        // font-size: 1rem;
    }

    .graySpan
    {
        z-index: 500;
        color: $gray;
    }


</style>