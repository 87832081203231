<template>
    <div class="backdrop" @click.self="closeModal">
        <div class="modal" :class="{ sale: theme === 'sale'}" @keyup.37="changeImgWithKeyboard('left')" @keyup.39="changeImgWithKeyboard('right')" tabindex="0">
            <div class="modal__close">
                <i class="fi fi-rr-cross-small icon" id="selectorIcon" @click.self="closeModal"></i>
            </div>
            <div class="modal__currentImage">
                <img class="productImg" :src="mainImage" alt="Komputery poleasingowe i laptopy uzywane">
            </div>

            <div v-if="product.imageGallery.length > 1" class="modal__sliderContainer">
                <div :class="{'modal__moveImages__option': true, 'disabledIcon': sliderData.imageSliderId === 0}" @click="changeImage('previous')"><i class="fi fi-rr-arrow-small-left"></i></div>
                <div :class="{modal__sliderContainer__photoPicker: true, modal__sliderContainer__photoPicker2: product.imageGallery.length === 2}">
                  <div 
                  class="modal__sliderContainer__photoPicker__slide"
                  :style="{
                    left: sliderData.computedLeft + 'px'
                  }">
                    <div
                    v-for="(imgData, imgIndx) in product.imageGallery"
                    :key="imgIndx"
                    :class="{modal__sliderContainer__photoPicker__slide__image: true, modal__sliderContainer__photoPicker__slide__picked: (mainImage === product.imageGallery[imgIndx].url)}"
                    :ref="'imageSlider'+imgIndx"
                    @click="updateMainImage(imgData.url,imgIndx)">
                    
                    <img
                    :src="product.imageGallery[imgIndx].url"
                    />
                    </div>
                  </div>
                </div>
                <div :class="{'modal__moveImages__option': true, 'hidden': true, 'disabledIcon': sliderData.imageSliderId + 3 === product.imageGallery.length}" @click="changeImage('next')"><i class="fi fi-rr-arrow-small-right"></i></div>
                <div :class="{'modal__moveImages__option': true, 'hidden2': true, 'disabledIcon': sliderData.imageSliderId + 2 === product.imageGallery.length}" @click="changeImage('next2')"><i class="fi fi-rr-arrow-small-right"></i></div>
              </div>
            <!-- <div class="modal__moveImages">
                <div :class="{'modal__moveImages__option': true, 'disabledIcon': currentImage.indx === 0}" @click="changeImage('previous')"><i class="block fi fi-rr-arrow-small-left"></i></div>
                <div :class="{'modal__moveImages__option': true, 'disabledIcon': currentImage.indx === product.imageGallery.length-1}" @click="changeImage('next')" ><i class="block fi fi-rr-arrow-small-right"></i></div>
            </div> -->
            <!--
            <div class="modal__imageSelector" v-for="photoi in Math.ceil(product.imageGallery.length / 10)" :key="photoi">
                <div :class="{'modal__imageSelector__option': true, 'current': currentImage.url === image.url ? true : false}" v-for="image in product.imageGallery.slice((photoi - 1) * 10, photoi * 10)" :key="image.url" @click="emitSelectedChange(image)">
                    <img :src="image.url">
                </div>
            </div>
            -->
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
        }
    },
    props: ['theme','currentImage','product', 'mainImage', 'sliderData'],
    methods: {
        changeImgWithKeyboard(key)
        {
            if(key==="left")
            {
            this.changeImage('previous');
            if(this.mainImageIndex-1 >= 0)
            {
                this.updateMainImage(this.product.imageGallery[this.mainImageIndex-1].url,this.mainImageIndex-1)
            }
            
            }
            if(key==="right")
            {
                this.changeImage('next');
                if(this.mainImageIndex+1 < this.product.imageGallery.length)
                {
                    this.updateMainImage(this.product.imageGallery[this.mainImageIndex+1].url,this.mainImageIndex+1)
                } 
            }
        },
        updateMainImage(url,indx){
        this.mainImage = url;
        this.mainImageIndex = indx;
        this.$emit('updateUrl', url);
        this.$emit('updateImgIndx', indx);
        },
        closeModal() {
            this.$emit('close')
        },
        emitSelectedChange(image)
        {
            this.$emit('photoChange', image)
        },
        changeImage(move)
        {
            if(move === "next")
            {
                if(this.sliderData.imageSliderId + 3 < this.product.imageGallery.length){
                    const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                    const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId+1)];
                    const firstImageBounding = firstImage[0].getBoundingClientRect();
                    const secondImageBounding = secondImage[0].getBoundingClientRect();
                    this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                    this.sliderData.imageSliderId = this.sliderData.imageSliderId + 1
                }
            }
            if(move === "next2")
            {
              if(this.sliderData.imageSliderId + 2 < this.product.imageGallery.length){
                const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId+1)];
                const firstImageBounding = firstImage[0].getBoundingClientRect();
                const secondImageBounding = secondImage[0].getBoundingClientRect();
                this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                this.sliderData.imageSliderId = this.sliderData.imageSliderId + 1
              }
            }
            if(move === 'previous')
            {
                if(this.sliderData.imageSliderId > 0){
                    const firstImage = this.$refs["imageSlider" + this.sliderData.imageSliderId];
                    const secondImage = this.$refs["imageSlider" + (this.sliderData.imageSliderId-1)];
                    const firstImageBounding = firstImage[0].getBoundingClientRect();
                    const secondImageBounding = secondImage[0].getBoundingClientRect();
                    this.sliderData.computedLeft = this.sliderData.computedLeft + (firstImageBounding.left - secondImageBounding.left);
                    this.sliderData.imageSliderId = this.sliderData.imageSliderId - 1
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.icon
{
    font-size: 1.563rem;
}

.icon:hover
{
    color: $red;
    cursor: pointer;
}

.hidden2{
    display: none !important;
}

.modal{
    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 95vh;
    overflow: hidden;
    padding: 20px;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            &__sliderContainer{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 4.768rem;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              margin-top: 1rem;
              user-select: none;
              -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
              -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
              &__photoPicker{
                  width: 23.953rem;
                  height: 4.768rem;
                  position: relative;
                  overflow-x: hidden;
                  overflow-y: hidden;
                  margin-top: 0.21rem;
                &__slide{
                  display: flex;
                  position: absolute;
                  left: 0;
                  transition: 0.3s left ease-in-out;
                  &__image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #d8d8d8;
                    width: 7.451rem;
                    height: 4.768rem;
                    background: white;
                    margin-right: 0.8rem;
                    transition: border 0.2s ease-in;
                    padding: 0.41rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    img{
                      max-height: 100%;
                      max-width: 100%;
                    }
                  }
                  &__image:last-child{
                    margin-right: 0;
                  }
                  &__image:hover{
                    cursor: pointer;
                    border: 1px solid rgba(191, 80, 87, 0.8)
                  }
                  &__picked{
                    border: 1px solid $red;
                  }
                }
              }
              &__photoPicker2{
                width: 15.702rem !important;
              }
            }
    &__moveImages
    {
        display: flex;
        padding-right: 5%;
        padding-left: 5%;
        width: 90%;
        font-size: 2.441rem;
        align-items: center;
        justify-content: center;
        
        color: $gray;

        &__option
        {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            margin-right: 1rem;
            margin-left: 1rem;
            cursor: pointer;
            font-size: 1.953rem;
            i::before{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__option:hover
        {

            color: $red;
        }
    }

    &__imageSelector
    {
        display: flex;
        width: 100%;
        min-height: 20%;
        justify-content: center;
        align-items: center;
        overflow: auto;

        &__option
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 
            4.768rem;
            height: 
            4.768rem;
            overflow: hidden;
            border: 1px solid $border;
            margin-left: 1rem;
            padding: 0.512rem;
            cursor: pointer;
            border-radius: 5px;
            opacity: 1;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: opacity 0.3s ease-in;

            

            img{
                width:100%;
                height:100%;
                border-radius: 5px;
                object-fit: cover;
            }
        }

        &__option:hover
        {
            opacity: 0.7;
        }
    }

    &__currentImage
    {
        margin-bottom: 1rem;
        width: 100%;
        height: 100%;
        border: 1px solid $border;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        overflow: hidden;

        img
        {
            
            max-width:100%;
            max-height:100%;
            object-fit: contain;
        }





    }

    &__close
    {
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-end;
        align-items: center;
    }
}
.backdrop {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.modal h1 {
    color: #03cfb4;
}
.modal p{
    font-style: normal;
}
.modal .actions{
text-align: center;
margin: 30px 0 10px 0;
}
.modal .actions a {
color: #333;
padding: 8px;
border: 1px solid #eee;
border-radius: 4px;
text-decoration: none;
margin: 10px
}
.modal.sale {
    background-color: crimson;
    color: white;
}
.modal.sale h1 {
    color:white;
}
.modal.sale .actions{
color: white;
}
.modal.sale .actions a{
    color: white;
}

.current
{
    border: 1px solid $red;
}

.block
{
    display: block;
}

.disabledIcon
{
    color: $border;
}

.disabledIcon:hover
{
    color: $border;
}

.productImg
{
  transition: transform 0.4s ease-in;
}

.productImg:hover
{
  transform: scale(1.10);
}

    @media (max-width: 500px) {
    .hidden{
      display: none !important;
    }
    .hidden2{
      display: block !important;
    }
  }
</style>